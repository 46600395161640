import React, { createContext, useContext, useMemo, useState } from 'react';
import { Header } from '@cloudscape-design/components';
import { WidgetConfig, WidgetProviderProps } from '../interfaces';
import { allContent, Content, WidgetPreferences } from '../preferences';
import AgentSummaryStatsContent from './agentSummaryStatsContent';

export const getAgentSummaryStats: WidgetConfig = {
  definition: { defaultRowSpan: 1.5, defaultColumnSpan: 2, minRowSpan: 1.5 },
  data: {
    icon: 'table',
    title: 'Agent performance',
    description: 'Table with key metrics, grouped by agent',
    header: ChartHeader,
    content: AgentSummaryStatsContent,
    provider: AgentSummaryStatsProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface AgentSummaryStatsWidgetContextType {
  agentCount: number;
  setAgentCount: (count: number) => void;
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
}

export const AgentSummaryStatsWidgetContext = createContext<AgentSummaryStatsWidgetContextType>({
  agentCount: 0,
  setAgentCount:  () => null,
  visibleContent: [],
  openPreferences: () => null,
});

function AgentSummaryStatsProvider({ children, widgetId }: WidgetProviderProps) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] = useState<ReadonlyArray<Content>>(allContent);
  const [agentCount, setAgentCount] = useState<Readonly<number>>(0);

  const openPreferences = () => setPreferencesVisible(true);

  const value = useMemo(
    () => ({
      agentCount,
      setAgentCount,
      visibleContent,
      openPreferences,
    }),
    [agentCount],
  );
  return (
    <AgentSummaryStatsWidgetContext.Provider {...{ value }}>
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Operational metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </AgentSummaryStatsWidgetContext.Provider>
  );
}

function ChartHeader() {

  const { agentCount } = useContext(AgentSummaryStatsWidgetContext)
  return (
    <Header
      variant='h2'
      // description='Table with key metrics, grouped by agent'
    >
      Agent Performance {agentCount ? `(${agentCount})` : ''}
    </Header>
  );
}
