import Box from '@cloudscape-design/components/box';
import ColumnLayout from '@cloudscape-design/components/column-layout';
import Modal from '@cloudscape-design/components/modal';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';

import QueuePicker from '../../components/queue-picker';
import FormField from '@cloudscape-design/components/form-field';

interface WidgetPreferencesProps {
  onDismiss: () => void;
  widgetId: string;
}

export function WidgetPreferences({ onDismiss, widgetId }: Readonly<WidgetPreferencesProps>) {

  return (
    <Modal
      visible={true}
      header='Preferences'
      footer={
        <Box float='right'>
          <SpaceBetween
            direction='horizontal'
            size='xs'
          >
            <Button
              variant='link'
              formAction='none'
              onClick={onDismiss}
            >
              Close
            </Button>
          </SpaceBetween>
        </Box>
      }
      onDismiss={onDismiss}
    >
      <ColumnLayout
        columns={1}
        borders='horizontal'
      >
        <FormField
          description='Select a queue from the dropdown below to view its realtime data'
          label='Queue'
        >
          <QueuePicker widgetId={widgetId} />
        </FormField>
      </ColumnLayout>
    </Modal>
  );
}
