import React from 'react';
import styles from './agentStatus.module.scss';
import { AgentStatus } from './agentStatus';
import { AgentStatusesObjects } from './types';
import { getAgentStatusType } from './agentStatus.helpers';

const AgentStatusWidget: React.FC<{ agents: AgentStatusesObjects }> = ({ agents }) => {
  return (
    <div className={styles.agentStatusContainer}>
      {agents.map((agent) => (
        <section
          className={styles.agentItem + ' ' + styles[getAgentStatusType(agent.currentStatus.name, agent.currentStatus.type)]}
          key={`${agent.firstName}-${agent.lastName}`}
        >
          <AgentStatus agent={agent} />
        </section>
      ))}
    </div>
  );
};

export default AgentStatusWidget;
