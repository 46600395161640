import { AgentStatusType, AgentStatusTypes } from './types';

export const getAgentStatusType = (name: string, status: AgentStatusType): AgentStatusType => {
  if (status === 'ROUTABLE' && name === 'Connected') {
    return AgentStatusTypes.OnCall;
  }

  if(status === 'ROUTABLE' && name === 'ACW') {
    return AgentStatusTypes.ACW;
  }

  return status;
};
