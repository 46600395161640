import { Spinner } from '@cloudscape-design/components';
import useIntuitionSWR from '../../../../../../utils/hooks/useIntuitionSWR';
import { EmptyState } from '../../components/empty-state';
import WidgetTable from './table';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import { queueArnsToQueueIds } from '../../utils/widget.helpers';
import { WidgetContentProps } from '../interfaces';
import { useContext, useEffect } from 'react';
import { ContactTraceRecordWidgetContext } from './provider';

export default function ContactTraceRecord(props: WidgetContentProps) {
  const { getWidget } = useDashboardManager();
    const { setAgentCount } = useContext(ContactTraceRecordWidgetContext);
  const widget = getWidget(props.widgetId);
  const queues: string[] | null = widget.filters?.queues ?? null;

  const params = new URLSearchParams();
  params.append('templateType', 'getAgentTraceRecord');
  params.append('queues', queueArnsToQueueIds(queues));
  const endpoint = `/queries?${params.toString()}`;

  const { data, isLoading, error } = useIntuitionSWR<any>(endpoint, {
    shouldRetryOnError: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 5000,
  });

  useEffect(() => setAgentCount(data?.data?.length || 0), [data?.data])

  if (error) {
    return (
      <EmptyState
        title='Error retrieving data'
        description='This could be due to no data being received for this connect instance'
        verticalCenter={true}
      />
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <WidgetTable
      widgetId={props.widgetId}
      isLoading={isLoading}
      data={data?.data}
      error={error}
    />
  );
}
