import React, { createContext, useState, useMemo } from 'react';
import { WidgetPreferences } from './preferences';

interface ContactTraceRecordWidgetContextType {
  agentCount: number,
  setAgentCount: (count: number) => void,
  openPreferences: () => void;
}

export const ContactTraceRecordWidgetContext = createContext<ContactTraceRecordWidgetContextType>({
  openPreferences: () => null,  
  agentCount: 0,
  setAgentCount:  () => null,
});

export const Provider = ({ children, widgetId }: { children: React.ReactElement; widgetId: string }) => {
  const [preferencesVisible, setPreferencesVisible] = useState<boolean>(false);
    const [agentCount, setAgentCount] = useState<Readonly<number>>(0);
  
  const contextValue = useMemo(() => ({
    agentCount,
    setAgentCount,
    openPreferences: () => setPreferencesVisible(true),
  }), [agentCount]);
  
  return (
    <ContactTraceRecordWidgetContext.Provider
      value={contextValue}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Operational metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}  
    </ContactTraceRecordWidgetContext.Provider>
  );
};



