import React, { createContext, useState } from 'react';
import { Header, Spinner } from '@cloudscape-design/components';
import { WidgetConfig } from '../interfaces';
import GetWidgetData from '../../../../../../utils/getWidgetData';
import { allContent, Content, WidgetPreferences } from '../preferences';
import SentimentLineChart from './lineChart';
import Alert from '@cloudscape-design/components/alert';
import { useDashboardContext } from '../../context/dashboardContext';
import { useDashboardManager } from '../../hooks/useDashboardManager';
import styles from './styles.module.scss';

export const getSentimentByAgent: WidgetConfig = {
  definition: { defaultRowSpan: 1, defaultColumnSpan: 1, minRowSpan: 1 },
  data: {
    icon: 'mixedContent',
    title: 'Sentiment by Agent',
    description: 'Displays the sentiment, by agent',
    header: ChartHeader,
    content: SentimentByAgentContent,
    provider: SentimentByAgentProvider,
    staticMinHeight: 560,
    multipleAllowed: true,
  },
};

interface SentimentByAgentWidgetContextType {
  visibleContent: ReadonlyArray<Content>;
  openPreferences: () => void;
}

interface SentimentByAgentContentProps {
  widgetId: string;
}
const SentimentByAgentWidgetContext = createContext<SentimentByAgentWidgetContextType>({
  visibleContent: [],
  openPreferences: () => null,
});

function SentimentByAgentProvider({ children, widgetId }: { children: React.ReactElement; widgetId: string }) {
  const [preferencesVisible, setPreferencesVisible] = useState(false);
  const [visibleContent, setVisibleContent] = useState<ReadonlyArray<Content>>(allContent);
  return (
    <SentimentByAgentWidgetContext.Provider
      value={{
        visibleContent,
        openPreferences: () => setPreferencesVisible(true),
      }}
    >
      {React.cloneElement(React.Children.only(children), {
        removeConfirmationText: 'Operational metrics',
        actions: [{ text: 'Preferences', onClick: () => setPreferencesVisible(true) }],
      })}
      {preferencesVisible && (
        <WidgetPreferences
          widgetId={widgetId}
          preferences={visibleContent}
          onConfirm={(visibleContent) => {
            setVisibleContent(visibleContent);
            setPreferencesVisible(false);
          }}
          onDismiss={() => setPreferencesVisible(false)}
        />
      )}
    </SentimentByAgentWidgetContext.Provider>
  );
}

function ChartHeader() {
  return (
    <Header
      variant='h2'
      description=''
    >
      Sentiment by agent
    </Header>
  );
}

export default function SentimentByAgentContent(props: SentimentByAgentContentProps) {
  const { widgetId } = props;
  const { dashboard } = useDashboardContext();
  const { getWidget } = useDashboardManager();
  const widget = getWidget(widgetId);
  const period = dashboard.data.period;
  const { data, isLoading, error } = GetWidgetData({ widget, period });

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Alert
        statusIconAriaLabel='Error'
        type='error'
        header='Unable to load widget'
      >
        {error.status}
      </Alert>
    );
  }
  return (
    <div className={styles.fullWidth}>
      <SentimentLineChart data={data?.data} />
    </div>
  );
}
