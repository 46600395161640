import * as React from 'react';
import LineChart from '@cloudscape-design/components/line-chart';
import { commonChartProps, dateTimeFormatter, lineChartInstructions } from '../chart-commons';

interface SentimentData {
  interval_start: string;
  agent_username: string;
  customer_average_sentiment: number | null;
  agent_average_sentiment: number | null;
  contact_count: string;
}

interface HeatmapProps {
  data: SentimentData[];
}

interface TransformedDataItem {
  title: string;
  type: 'line';
  data: { x: Date; y: number }[];
}

const transformData = (data: SentimentData[]): TransformedDataItem[] => {
  const transformedData: Record<string, { x: Date; y: number }[]> = {};

  data.forEach((item) => {
    if (!transformedData[item.agent_username]) {
      transformedData[item.agent_username] = [];
    }
    if (item.customer_average_sentiment !== null) {
      transformedData[item.agent_username].push({
        x: new Date(item.interval_start),
        y: item.customer_average_sentiment,
      });
    }
    // Leave this here as we can split this report out as avg agent vs customer
    // if (item.agent_average_sentiment !== null) {
    //   transformedData[item.agent_username].push({
    //     x: new Date(item.interval_start),
    //     y: item.agent_average_sentiment,
    //   });
    // }
  });

  return Object.keys(transformedData).map((key) => ({
    title: key,
    type: 'line',
    data: transformedData[key],
  }));
};

const SentimentLineChart: React.FC<HeatmapProps> = ({ data }) => {
  const transformedData = transformData(data);

  const allDates = transformedData.flatMap((dataSeries) => dataSeries.data).map((data) => new Date(data.x).getTime());

  const minDate = new Date(Math.min(...allDates));
  const maxDate = new Date(Math.max(...allDates));

  return (
    <LineChart
      {...commonChartProps}
      hideFilter={true}
      fitHeight={true}
      height={25}
      series={transformedData}
      yDomain={[-5, 5]}
      xDomain={[minDate, maxDate]}
      xScaleType='time'
      xTitle='Time'
      yTitle={`Customer Sentiment`}
      ariaLabel='Sentiment by agent over time'
      ariaDescription={`Line chart showing sentiment containment rates ${lineChartInstructions}`}
      i18nStrings={{
        ...commonChartProps.i18nStrings,
        filterLabel: 'Filter',
        filterPlaceholder: 'Filter',
        xTickFormatter: dateTimeFormatter,
      }}
    />
  );
};

export default SentimentLineChart;
